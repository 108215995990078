import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@/model/form-control';
import { QueryPageModel } from '@/model/query-model';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { dateFormat } from '@/filter/date-format';

const goodList = [
    {
        name: '设备',
        value: 'device'
    },
    {
        name: '备品备件',
        value: 'spareAsset'
    },
    {
        name: '耗材',
        value: 'consumableItem'
    },
    {
        name: '其他',
        value: 'other'
    }
];

const typeList = [
    {
        name: '生产工厂',
        value: 'factory'
    },
    {
        name: '代理商',
        value: 'agency'
    },
    {
        name: '服务商',
        value: 'serviceProvider'
    },
    {
        name: '其他',
        value: 'other'
    }
];

export class SupplierEntityModel extends BaseEntityModel {
    @FormControl({
        label: '供应商名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '类型',
        type: FormControlType.SELECT,
        required: true,
        options: typeList
    } as FormControlOptionModel)
    type: string = undefined;

    @FormControl({
        label: '商品',
        type: FormControlType.CHECKBOX_GROUP,
        required: true,
        options: goodList
    } as FormControlTextModel)
    goods: Array<string> = [];

    @FormControl({
        label: '联系人名称',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    peopleName: string = undefined;

    @FormControl({
        label: '联系人职位',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    position: string = undefined;

    @FormControl({
        label: '联系人电话',
        type: FormControlType.TEXT,
        // pattern: /^1[3|4|5|7|8][0-9]\d{8}$/
        validator: (rule, value, callback) => {
            let pattern = /^1[3|4|5|7|8][0-9]\d{8}$/;
            if (value && !pattern.test(value)) {
                callback(new Error('请输入正确的手机号码'));
            } else {
                callback();
            }
        }
    } as FormControlTextModel)
    tel: string = undefined;

    @FormControl({
        label: '联系人邮箱',
        type: FormControlType.TEXT,
        // pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
        validator: (rule, value, callback) => {
            let pattern = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
            if (value && !pattern.test(value)) {
                callback(new Error('请输入正确的邮箱地址'));
            } else {
                callback();
            }
        }
    } as FormControlTextModel)
    email: string = undefined;

    @FormControl({
        label: '地址',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    address: string = undefined;

    @FormControl({
        label: '备注',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    comment: string = undefined;

    updateDate: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '供应商名称',
                dataIndex: 'name'
            },
            {
                title: '类型',
                dataIndex: 'type',
                customRender: (text, record, index) => {
                    if (record.type) {
                        const match = _.filter(typeList, item => { return item.value === record.type; });
                        if (match) {
                            return match[0].name;
                        }
                        return '';
                    } else {
                        return '';
                    }
                }
            },
            {
                title: '商品',
                dataIndex: 'goods',
                customRender: (text, record, index) => {
                    if (record.goods && record.goods.length > 0) {
                        const val = record.goods.map(item => {
                            const match = _.filter(goodList, good => { return good.value === item; });
                            if (match) {
                                return match[0].name;
                            }
                            return '';
                        });
                        if (val) {
                            return val.join('、');
                        } else {
                            return '';
                        }
                    } else {
                        return '';
                    }
                }
            },
            {
                title: '联系人',
                dataIndex: 'peopleName'
            },
            {
                title: '联系人电话',
                dataIndex: 'tel'
            },
            {
                title: '更新时间',
                dataIndex: 'updateDate',
                customRender: (text, record, index) => {
                    return dateFormat(text);
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        const tempGoods = _.get(json, 'goods');
        if (tempGoods) {
            this.goods = tempGoods.split(',');
        } else {
            this.goods = [];
        }
        return this;
    }

    toService() {
        const data: any = super.toService();
        if (data.goods && data.goods.length > 0) {
            data.goods = data.goods.join(',');
        } else {
            data.goods = '';
        }
        return data;
    }
}

export class SupplierQueryModel extends QueryPageModel {
    @QueryControl({
        label: '类型',
        type: QueryControlType.SELECT,
        options: typeList
    })
    type: string = undefined;

    @QueryControl({
        label: '商品',
        type: QueryControlType.SELECT,
        options: goodList
    })
    goods: string = undefined;

    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT
    })
    keyWords: string = undefined;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
